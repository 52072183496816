<template>
  <div id="defenseGameWrapper">

    <!-- Fondo video loop -->
    <VideoBackgroundVue v-show="ShowVideoLoop" :playlist="options.standByVideos" />
    
    <!-- Usuarios que estan por jugar -->
    <ScreenQueue :darkmode="true" v-if="gameLoaded && ShowVideoLoop && players.length">
      <template v-slot:bottom>
        <div class="has-text-weight-bold is-size-3">{{ $t('exp_trivia.challengeOthers') }}</div>
        <div v-if="options.queue.disableCountdown || players.length < options.queue.minPlayers" class="waiting is-size-4">{{ $t('exp_trivia.waitingPlayers') }}</div>
        <div v-else class="timer">
          <b-tag class="has-color-black-bis" size="is-large">
            <d-icon
            icon="FaClock"
            size="is-small"
            class="ml-3 mr-1"/> 
            {{ remainingTimeToStart }}
          </b-tag>
          <div class="is-size-5 has-text-centered">{{ $t('exp_trivia.toStart') }}</div>
        </div>
      </template>
    </ScreenQueue>
    
    <section id="loadingUnityHero" class="hero" v-if="!gameLoaded">
      <div class="hero-body">
        <p class="title">
          {{ $t('exp_external.defenseLoadingGame') }} 
        </p>
        <p class="subtitle">
          {{ $t('exp_external.defenseLoadingMsg') }} 
        </p>
      </div>
    </section>
  
    <!-- JUEGO -->
    <VueUnity id="vueunity" :class="{show: showUnity}" :unity="unityContext" width="1920" height="1080" />

    <qr-box :darkmode="true"/>
    <user-list type="active" :autohide="false" :limit="6" :darkmode="true"/>
  </div>
</template>

<script>
import qrBox from './qrBox.vue';
import UserList from './userList.vue';
import UnityWebgl from 'unity-webgl';
import VueUnity from 'unity-webgl/vue';
import VideoBackgroundVue from './VideoBackground.vue';
import ScreenQueue from './screenQueue.vue';

const Unity = new UnityWebgl({
  loaderUrl:          '/assets/defense/build/DefenseGame.loader.js',
  dataUrl:            '/assets/defense/build/DefenseGame.data.unityweb',
  frameworkUrl:       '/assets/defense/build/DefenseGame.framework.js.unityweb',
  codeUrl:            '/assets/defense/build/DefenseGame.wasm.unityweb',
  streamingAssetsUrl: '/assets/defense/streamingassets/',
});

// Unity.on('device', () => alert('click device ...'));
// eslint-disable-next-line
// Unity.on('progress', (percent) => console.log('Unity progress: ', percent))
// eslint-disable-next-line


export default {
  components: {
    qrBox,
    UserList,
    VueUnity,
    VideoBackgroundVue,
    ScreenQueue,
  },
  data() {
    return {
      remainingTimeToStart: "00:00",
      unityContext: Unity,
      gameLoaded: false,
      timerWebWorkers: null,
    }
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  computed: {
    serverState() {
      return this.$store.state.space.serverState;
    },
    ShowVideoLoop() {
      if (this.serverState < 2) return true
      return false
    },
    players() {
      return this.$store.state.space.players.filter(x => !x.queue)
    },
    showUnity() {
      return this.gameLoaded && this.serverState >= 2
    },
  },
  watch: {
    async serverState(newState) {
      if (newState === 1) {
        let endTime = new Date().getTime() + (this.options.queue.countdownDuration * 1000);
        this.timerWebWorkers.postMessage({ endTime});
      }else{
        this.timerWebWorkers.postMessage({ pause:true });
      }
    },
  },
  methods: {
    UnloadUnity() {
      this.unityContext.unload();
    },
  },
  sockets: {
    
  },
  mounted() {
    // Unity.on('loaded', (percent) => {
    //   console.log('Unity loaded: success')})
    // eslint-disable-next-line
    Unity.on('created', (percent) => {
      // Le mando la data de conexion a unity
      Unity.send("Douob", "SetServerUrl", process.env.VUE_APP_SOCKET_URL);
      Unity.send("Douob", "SetSpaceSlug", this.$store.state.space.spaceSlug);
      Unity.send("Douob", "ConnectToServer");
      this.gameLoaded = true
    });

    Unity.on('destroyed', () => {
      console.log('Unity destroyed')
      this.gameLoaded = false
    })
    



    this.timerWebWorkers = new Worker(new URL("@/workers/CountdownWorker.js", import.meta.url));
    this.timerWebWorkers.onmessage = ({ data }) => {
      // console.log(data.timeString, data.timeNormalized);
      this.remainingTimeToStart = data.timeString
      this.remainingTimeQuestion = data.timeNormalized
    }
  },
  beforeDestroy() {
    console.log('beforeDestroy + unityContext.unload')
    this.unityContext.unload();

    if (this.timerWebWorkers) {
      this.timerWebWorkers.terminate();
    }
  
  },

};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

#defenseGameWrapper {
  // Estilos placeholder
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; 
  background-color: black;

  h1 {
    font-size: 70px;
  }
  

  .timer {
    display: flex;
    margin-top: 0.5rem;

    :nth-child(2) {
      margin-left: 1rem;
    }
  }
}
#vueunity{
  position: absolute;
  top: 0;
  left: 100vw;
  &.show{
    left: 0vw;
  }
}

#loadingUnityHero {
  position: absolute;
  top: 75vh;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  align-items: center;
  .hero-body {
    text-align: center;
    background: white;
    border-radius: calc($general-border-radius * 2);
    flex-grow: unset;
  }
}
</style>

<style lang="scss">
#defenseGameWrapper {
 
  .bottom {
    justify-content: center;
  }
  #userlist {
    transform: translate(-50%, -0%);
    top: 150px;
    margin-left: -6px;
  }
}
#countdownPlayerList {
  .top {
    margin: 2rem;
  }
}

</style>