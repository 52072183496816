<template>
  <div id="countdownPlayerList" class="has-text-black-bis" :class="[{darkmode: darkmode}]">
    <div class="top">
      <div class="is-size-2 has-text-weight-bold">{{ $t('system.getReady') }}</div>
      <div class="nextRoundPlayers" :class="'mp-' + options.queue.maxPlayers">
        <!-- Proximos jugadores -->
        <!-- TODO transition-group -->
        <player-avatar v-for="(player, i) in players" :player="player" :key="player.id" :scale="3" :showUsername="true"
          :class="[i < options.queue.minPlayers ? 'minPlayer' : 'availablePlayer']" />
        <!-- Jugadores placeholders -->
        <player-avatar-placeholder v-for="i in options.queue.maxPlayers - players.length" :key="i" :scale="3"
          :class="[i + players.length <= options.queue.minPlayers ? 'minPlayer' : 'availablePlayer']" />
      </div>
    </div>
    <div class="bottom" v-if="showBottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
import PlayerAvatar from '../PlayerAvatar.vue';
import PlayerAvatarPlaceholder from '../PlayerAvatarPlaceholder.vue';
import './screenQueue.scss';

export default {
  components: {
    PlayerAvatar,
    PlayerAvatarPlaceholder
  },
  props: {
    title: {
      type: String,
      default: "Prepare to play!",
    },
    showBottom: {
      type: Boolean,
      default: true,
    },
    darkmode: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    options() {
      return this.$store.getters["space/config"].experience.component[0];
    },
    players() {
      return this.$store.state.space.players.filter(x => !x.queue)
    },
  }
};
</script>

